import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, Alert, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import {
  openNotificationWithIcon,
  validatePhoneNumber,
} from "../../utils/helper";
import { formValuesInterface } from "./siginin-interface";
import { nanoid } from "nanoid";
import Inputwithaddon from "../../components/inputwithaddon/inputwithaddon";
import { signin } from "../../api/base/base";
import { useDispatch } from "react-redux";
import {
  loginUser,
  logoutUser,
  updateUser,
} from "../../store/userSlice/userSlice";
import { setAuthToken } from "../../api";

const Signin = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([
    {
      id: nanoid(),
      name: "Email",
      active: false,
    },
    {
      id: nanoid(),
      name: "Phone",
      active: true,
    },
  ]);

  const [errors, setErrors] = useState({
    phone: "",
    pin: "",
    email: "",
  });

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const switchTabs = (valueIndex: number) => {
    const newTabs = [...tabs];
    newTabs.forEach((item, index) => {
      item.active = false;
      if (valueIndex === index) {
        item.active = true;
      }
    });
    setTabs(newTabs);

    form.setFieldsValue({
      email: undefined,
    });
    form.setFieldsValue({
      phone: undefined,
    });
  };

  const onFinish = async (values: formValuesInterface) => {
    const { email, phone, pin, remember_me } = values;

    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    if (tabs[0].active) {
      newErrors["phone"] = "";
    } else {
      if (!validatePhoneNumber(`+234${phone}`)) {
        newErrors["phone"] = "Invalid phone number";
      } else {
        newErrors["phone"] = "";
      }
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await signin({
          pin,
          phone: tabs[0].active ? email : `+234${phone}`,
          login_message: "",
          remember_me: remember_me ? "Yes" : "No",
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          if (data.token === undefined) {
            openNotificationWithIcon("warning", "Sign In", message);

            dispatch(
              updateUser({
                user: data,
              })
            );

            navigate("/signin");
          } else {
            openNotificationWithIcon("success", "Sign In", message);

            dispatch(
              loginUser({
                user: data,
                token: data.token,
              })
            );

            setAuthToken(data.token);

            if (
              data?.kyc?.auth?.phone_verified === "No" ||
              data?.kyc?.auth?.email_verified === "No"
            ) {
              navigate("/dashboard/confirm-otp");
            } else {
              navigate("/dashboard");
            }
          }
        } else {
          setError(message);
        }

        setLoading(false);
      } catch (error: any) {
        // console.error(error);
        setLoading(false);
        message.error("Something went wrong: Sign up");
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  return (
    <div className="mt-20 mb-8">
      <div className="flex justify-center">
        <img src="/img/logo/fulllogo.png" alt="logo" className="w-20 mb-10" />
      </div>

      <h1 className="text-2xl font-semibold text-center mb-9 font-ibmplexsans text-appcolorblue">
        Welcome Back!
      </h1>

      {/* <div className="grid grid-cols-2 rounded-2xl bg-appcolorshade p-[0.37rem] w-11/12  lg:w-3/12 mx-auto mb-6">
        {
          tabs.map((item, index) => (
            <div className={`font-semibold text-xs text-center cursor-pointer py-4 font-poppins ${item.active ? 'bg-appcolorwhite rounded-xl' : 'bg-transparent'}`}
              onClick={() => {
                if (!loading) {
                  switchTabs(index)
                }
              }}
              key={item.id}
            >
              {item.name}
            </div>
          ))
        }
      </div> */}

      {error != null && (
        <div className="flex justify-center w-11/12 mx-auto mb-4 lg:w-3/12">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="flex justify-center w-11/12 mx-auto lg:w-3/12">
        <Form
          className="w-full"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          {tabs[0].active ? (
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Invalid Email",
                },
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          ) : (
            <Form.Item
              validateStatus={errors?.phone.length > 0 ? "error" : undefined}
              help={errors?.phone.length > 0 ? errors.phone : undefined}
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone Number is required",
                },
              ]}
            >
              <Inputwithaddon
                updateField={updateField}
                name="phone"
                placeHolder="8000000000"
                addonBefore="+234"
              />
            </Form.Item>
          )}

          <Form.Item
            label="PIN"
            name="pin"
            rules={[
              {
                required: true,
                message: "PIN is required",
              },
            ]}
          >
            <Input.Password placeholder="Enter your secured 6-Digit PIN number" />
          </Form.Item>

          <Form.Item name="remember_me" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <span
            className="text-sm font-normal cursor-pointer text-appcolorblue font-poppins"
            onClick={() => {
              navigate("/forgot-password");
            }}
          >
            Forgot Password?
          </span>

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto my-8">
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={loading}
                loading={loading}
              >
                Log In
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <p className="text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey">
        New to Nairex?
        <span
          className="pl-1 cursor-pointer text-appcolorblue"
          onClick={() => {
            if (!loading) {
              navigate("/signup");
            }
          }}
        >
          Create an account
        </span>
      </p>
    </div>
  );
};

export default Signin;
