import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import React, { useState } from "react";
import { deactivateaccountmodalInterface } from "../accounts-interface";
import {
  finalizeDeactivateAccount,
  initiateDeactivateAccount,
} from "../../../../api/base/base";
import { openNotificationWithIcon } from "../../../../utils/helper";
import { logoutUser } from "../../../../store/userSlice/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const Deactivateaccountmodal = ({
  visible,
  closeModal,
}: deactivateaccountmodalInterface) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [collectOTP, setCollectOTP] = useState(false);
  const [resending, setResending] = useState(false);

  const runInitiateDeactivateAccount = async (resending?: boolean) => {
    try {
      if (resending) {
        setResending(true);
      } else {
        setSubmitting(true);
      }

      const res = await initiateDeactivateAccount({
        mode: "phone",
      });

      const { status, message } = res.data;

      if (status === "success") {
        openNotificationWithIcon(
          "success",
          resending
            ? "Resend Deactivation Code"
            : "Initiate Deactivate Account",
          message
        );
        setCollectOTP(true);
      } else {
        openNotificationWithIcon(
          "error",
          resending
            ? "Resend Deactivation Code"
            : "Initiate Deactivate Account",
          message
        );
      }

      if (resending) {
        setResending(false);
      } else {
        setSubmitting(false);
      }
    } catch (error) {
      // console.error(error);
      debugger;
      if (resending) {
        setResending(false);
      } else {
        setSubmitting(false);
      }
      message.error(
        `Something went wrong: ${
          resending ? "Resend Deactivation Code" : "Initiate Deactivate Account"
        }`
      );
    }
  };

  const onFinish = async (values: any) => {
    try {
      setSubmitting(true);
      const res = await finalizeDeactivateAccount({
        otp: values?.otp,
      });

      const { status, message } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Deactivate Account", message);
        setCollectOTP(false);
        closeModal();
        navigate("/signin");
        dispatch(logoutUser());
      } else {
        openNotificationWithIcon("error", "Deactivate Account", message);
      }
      setSubmitting(false);
    } catch (error) {
      // console.error(error);
      setSubmitting(false);
      message.error("Something went wrong: Deactivate Account");
    }
  };

  return (
    <div>
      <Modal
        onCancel={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        onOk={
          submitting
            ? undefined
            : () => {
                closeModal();
              }
        }
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Deactivate Account
            </h1>
          </div>

          <div>
            {!collectOTP ? (
              <p className="text-sm font-poppins">
                Are sure you want to deactivate your account?
              </p>
            ) : (
              <>
                <Form className="w-full" onFinish={onFinish} layout="vertical">
                  <Form.Item
                    label={"Enter OTP sent to your Phone"}
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: "OTP is required",
                      },
                    ]}
                  >
                    <Input placeholder={"Enter OTP"} disabled={submitting} />
                  </Form.Item>
                  <Form.Item>
                    <Row justify="end">
                      <Col>
                        <Button
                          className="purple-skel"
                          size="small"
                          onClick={() => {
                            runInitiateDeactivateAccount(true);
                          }}
                          loading={resending}
                          disabled={resending || submitting}
                        >
                          Resend
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>

                  <div className="flex justify-between mt-4">
                    <Button
                      className="purple-skel w-[40%]"
                      disabled={submitting}
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      htmlType="submit"
                      className="w-[40%]"
                      disabled={resending || submitting}
                      loading={submitting}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </div>

          {!collectOTP && (
            <div className="flex justify-between mt-4">
              <Button
                className="purple-skel w-[40%]"
                disabled={submitting}
                onClick={closeModal}
              >
                Cancel
              </Button>

              <Button
                htmlType="submit"
                className="w-[40%]"
                disabled={resending || submitting}
                loading={submitting}
                onClick={() => {
                  runInitiateDeactivateAccount();
                }}
              >
                {!collectOTP ? "Initiate" : "Submit"}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Deactivateaccountmodal;
