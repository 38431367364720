import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import {
  ClipboardDocumentIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Alert, message } from "antd";
import {
  getSupportedBanks,
  getSupportedCurrencies,
  getVirtualAccounts,
} from "../../../api/wallet/wallets";
import Createvirtualaccount from "./createvirtualaccount";
import { openNotificationWithIcon } from "../../../utils/helper";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";

const Virtualaccounts = () => {
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [loadingCurrencies, setLoadingCurrencies] = useState(false);
  const [supportedCurrencies, setSupportedCurrencies] = useState<
    { currency: string }[]
  >([]);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const requestData = useMemo(() => {
    return {
      currency: user?.wallets[0].currency,
    };
  }, [user]);

  const getAccounts = useCallback(async () => {
    setLoadingAccounts(true);

    try {
      const res = await getVirtualAccounts({
        ...requestData,
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setAccounts(data);
        } else {
          setAccounts([]);
        }
      } else {
        message.warning(`Get wallet accounts, ${res.data.message}`);
      }

      setLoadingAccounts(false);
    } catch (error) {
      setLoadingAccounts(false);
      message.error("Something went wrong: Get wallet accounts");
    }
  }, [requestData]);

  useEffect(() => {
    getAccounts();

    // eslint-disable-next-line
  }, []);

  const closeModal = (refresh?: boolean) => {
    setShowCreateAccount(false);
    if (refresh) {
      getAccounts();
    }
  };

  // const fetchAvailableCurrencies = async () => {
  //   setLoadingCurrencies(true);
  //   try {
  //     const res = await getSupportedCurrencies();

  //     const { status, message, data } = res.data;

  //     if (status === "success") {
  //       setSupportedCurrencies(data);
  //     } else {
  //       openNotificationWithIcon("error", "Supported currencies", message);
  //     }

  //     setLoadingCurrencies(false);
  //   } catch (error) {
  //     setLoadingCurrencies(false);
  //     message.error("Something went wrong:  Supported currencies");
  //   }
  // };

  // useEffect(() => {
  //   fetchAvailableCurrencies();
  // }, []);

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/logo.png" alt="logo" className="w-16" />
      </div>

      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Fund Wallet
          </h1>

          <span
            className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcolorblue text-appcolorwhite"
            onClick={() => {
              if (!loadingAccounts) {
                setShowCreateAccount(true);
              }
            }}
          >
            Create
          </span>
        </div>

        {accounts.length > 0 && (
          <div className="mb-4">
            <Alert
              message={
                <span className="font-poppins">
                  Make a transfer to any of the account below and your wallet
                  will be funded instantly.
                </span>
              }
              type="info"
              className="w-full"
            />
          </div>
        )}

        <div className="pb-16">
          {loadingAccounts ? (
            <>
              {[1, 2, 3].map((item) => (
                <div className="mb-4" key={`trans-preloader-${item}`}>
                  <Skeletonloader height={"6rem"} />
                </div>
              ))}
            </>
          ) : (
            <>
              {accounts.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-2">
                  <div className="w-11 h-11">
                    <DocumentIcon className="cursor-pointer" />
                  </div>
                  <p className="text-center cursor-pointer font-poppins">
                    You don’t have a
                  </p>
                  <p className="text-center cursor-pointer font-poppins">
                    wallet account yet.{" "}
                    <span
                      className="text-appcolorblue"
                      onClick={() => {
                        if (!loadingAccounts) {
                          setShowCreateAccount(true);
                        }
                      }}
                    >
                      Create one
                    </span>
                  </p>
                </div>
              ) : (
                <div>
                  {accounts.map((item, index) => (
                    <div
                      className="p-4 mb-3 rounded-lg shadow-lg bg-appcolorlightgray"
                      key={`virtual-account-${index}-${item?.reference_code}`}
                    >
                      <h1 className="mb-2 text-sm">
                        <span className="font-semibold"> Bank Name</span>:{" "}
                        {item?.bank.toUpperCase()}
                      </h1>
                      <h1 className="mb-2 text-sm">
                        <span className="font-semibold"> Account Name</span> :{" "}
                        {item?.name}{" "}
                      </h1>
                      <h1 className="mb-2 text-sm">
                        <span className="font-semibold"> Account Number </span>:{" "}
                        {item?.number}
                      </h1>

                      <div className="">
                        <div className="flex mt-4">
                          <CopyToClipboard
                            text={`Bank Name: ${item?.bank.toUpperCase()}, Account Name: ${
                              item?.name
                            }, Account Number: ${item?.number} `}
                            onCopy={() => {
                              message.info(
                                "Account details copied to clipboard"
                              );
                            }}
                          >
                            <div className="flex items-center p-1 px-2 text-sm bg-appcolorgray rounded-[0.5rem]">
                              <span className="pr-8">{"Copy"}</span>
                              <ClipboardDocumentIcon className="w-4 h-4 text-appcolorwhite" />
                            </div>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {showCreateAccount && (
        <Createvirtualaccount
          visible={showCreateAccount}
          closeModal={closeModal}
          currency={user?.wallets[0].currency}
          loadingCurrencies={loadingCurrencies}
          supportedCurrencies={supportedCurrencies}
        />
      )}
    </div>
  );
};

export default Virtualaccounts;
