import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboardpage from "./components/dashboardpage/dashboardpage";
import AddmoneySuccess from "./pages/dashboard/addmoneysuccess/addmoneysuccess";
import Confirmotp from "./pages/dashboard/confirmotp/confirmotp";
import Createwallet from "./pages/dashboard/createwallet/createwallet";
import Createwalletsuccess from "./pages/dashboard/createwalletsuccess/createwalletsuccess";
import Continuekyc from "./pages/dashboard/continuekyc/continuekyc";
import Dashboardmain from "./pages/dashboard/dashboardmain/dashboardmain";
import KYCsuccess from "./pages/dashboard/kycsuccess/kycsuccess";
import Forgotpassword from "./pages/forgotpassword/forgotpassword";
import Onboardsuccess from "./pages/dashboard/onboardsuccess/onboardsuccess";
import Repayloan from "./pages/dashboard/repayloan/repayloan";
import Resetpassword from "./pages/resetpassword/resetpassword";
import Signup from "./pages/siginup/signup";
import Signin from "./pages/signin/signin";
import Slash from "./pages/splash/splash";
import Repayloansuccess from "./pages/dashboard/repayloansuccess/repayloansuccess";
import Repayloansuccesspartial from "./pages/dashboard/repayloansuccesspartial/repayloansuccesspartial";
import Requestloan from "./pages/dashboard/requestloan/requestloan";
import Protectedroute from "./components/protectedroutes/protectedroute";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { setAuthToken } from "./api";
import Cards from "./pages/dashboard/cards/cards";
import Transactions from "./pages/dashboard/transactions/transactions";
import Documents from "./pages/dashboard/documents/documents";
import Nextofkin from "./pages/dashboard/nextofkin/nextofkin";
import Loanstatement from "./pages/dashboard/loanstatement/loanstatement";
import Loanstatementsuccess from "./pages/dashboard/loanstatementsuccess/loanstatementsuccess";
import Changepassword from "./pages/dashboard/changepassword/changepassword";
import Support from "./pages/dashboard/support/support";
import Validate from "./pages/validate/validate";
import localStorage from "redux-persist/es/storage";
import Requestloansuccess from "./pages/dashboard/requestloansuccess/requestloansuccess";
import Accounts from "./pages/dashboard/accounts/accounts";
import Accountverification from "./pages/dashboard/accounts/accountverification";
import Addmoney from "./pages/dashboard/addmoney/addmoney";
import Virtualaccounts from "./pages/dashboard/virtualaccounts/virtualaccounts";
import Wallets from "./pages/dashboard/wallets/wallets";
import Transfers from "./pages/dashboard/transfers/transfers";
import Createbeneficiary from "./pages/dashboard/beneficiary/createbeneficiary";
import Viewtransactions from "./pages/dashboard/transactions/viewtransactions";
import Inhousecard from "./pages/dashboard/inhousecards/inhousecard";

import Errorviewscreen from "./pages/view/errorviewscreen";
import Offlineviewscreen from "./pages/view/offlineviewscreen";
import Webauth from "./pages/dashboard/webauth/webauth";

// import Buy from "./pages/dashboard/payment/buy";
import Loans from "./pages/dashboard/loans/loans";
import Viewloandetails from "./pages/dashboard/loans/viewloandetails";
import { ConfigProvider, ThemeConfig } from "antd";
import Accountstatus from "./pages/dashboard/accounts/accountstatus";
import Billpayment from "./pages/dashboard/billpayment/billpayment";
import Billpaymentrequestsuccess from "./pages/dashboard/billpayment/billpaymentrequestsuccess";
import Manageaccount from "./pages/dashboard/manageaccount/manageaccount";
import Updateemail from "./pages/dashboard/updateemail/updateemail";
import Banks from "./pages/dashboard/banks/banks";
import Createsavings from "./pages/dashboard/savings/createsavings";
import Requestsavingssuccess from "./pages/dashboard/savings/requestloansuccess";
import Payment from "./pages/dashboard/payment/payment";
import Paymentsuccess from "./pages/dashboard/paymentsuccess/paymentsuccess";
import Paymenthistory from "./pages/dashboard/payment/paymenthistory";
import Orders from "./pages/dashboard/businesstools/orders";
import Savings from "./pages/dashboard/savings/savings";
import BusinessTools from "./pages/dashboard/businesstools/businesstools";
import EnablePin from "./pages/dashboard/enablepin/enablepin";

function App() {
  const state = useSelector((state: RootState) => ({
    token: state.user.token,
  }));

  const { token } = state;
  setAuthToken(token);

  const [onlineStatus, setOnlineStatus] = useState(
    navigator.onLine ? "online" : "offline"
  );

  useEffect(() => {
    const reloadCount = localStorage.getItem("reloadCount");
    //@ts-ignore
    if (reloadCount < 2) {
      //@ts-ignore
      localStorage.setItem("reloadCount", String(reloadCount + 1));
      window.location.reload();
    } else {
      localStorage.removeItem("reloadCount");
    }
  }, []);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setInnerWidth(window.innerWidth);
    if (window.innerWidth > 600) {
      window.location.href = "/";
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener("resize", handleResize);

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateOnlineStatus = () => {
    setOnlineStatus(navigator.onLine ? "online" : "offline");
  };

  useEffect(() => {
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup event listeners when the component is unmounted
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []); // empty dependency array ensures that the effect is only run once on mount

  const offlineRoute = createBrowserRouter([
    {
      path: "/",
      element: <Offlineviewscreen />,
    },
    {
      path: "*",
      element: <Offlineviewscreen />,
    },
  ]);

  const routerCheck = createBrowserRouter([
    {
      path: "/",
      element: <Errorviewscreen />,
    },
    {
      path: "*",
      element: <Errorviewscreen />,
    },
  ]);

  const router = createBrowserRouter([
    {
      path: "/view",
      element: <Errorviewscreen />,
    },
    {
      path: "/",
      // element: <Slash />,
      element: <Signin />,
    },
    {
      path: "/validate/:reference",
      element: <Validate />,
    },
    {
      path: "/signin",
      element: <Signin />,
    },
    {
      path: "/forgot-password",
      element: <Forgotpassword />,
    },
    {
      path: "/reset-password",
      element: <Resetpassword />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/onboard-success",
      element: <Onboardsuccess />,
    },
    // {
    //   path: "/webauth",
    //   element: <Webauth />,
    // },
    {
      element: <Protectedroute />,
      children: [
        {
          path: "/dashboard",
          element: <Dashboardpage />,
          children: [
            {
              path: "/dashboard",
              element: <Dashboardmain />,
            },
            {
              path: "/dashboard/confirm-otp",
              element: <Confirmotp />,
            },
            {
              path: "/dashboard/transactions",
              element: <Transactions />,
            },
            {
              path: "/dashboard/transactions/:reference_code",
              element: <Viewtransactions />,
            },
            {
              path: "/dashboard/documents",
              element: <Documents />,
            },
            {
              path: "/dashboard/relationship",
              element: <Nextofkin />,
            },
            {
              path: "/dashboard/create-wallet",
              element: <Createwallet />,
            },
            {
              path: "/dashboard/wallet-success",
              element: <Createwalletsuccess />,
            },
            {
              path: "/dashboard/continue-kyc",
              element: <Continuekyc />,
            },
            {
              path: "/dashboard/kyc-success",
              element: <KYCsuccess />,
            },
            {
              path: "/dashboard/addmoney-success",
              element: <AddmoneySuccess />,
            },
            {
              path: "/dashboard/repay/:reference_code",
              element: <Repayloan />,
            },
            {
              path: "/dashboard/linked-cards",
              element: <Cards />,
            },
            {
              path: "/dashboard/linked-banks",
              element: <Banks />,
            },
            {
              path: "/dashboard/cards",
              element: <Inhousecard />,
            },
            {
              path: "/dashboard/request-loan",
              element: <Requestloan />,
            },
            {
              path: "/dashboard/request-loan-success",
              element: <Requestloansuccess />,
            },
            {
              path: "/dashboard/repayment-success",
              element: <Repayloansuccess />,
            },
            {
              path: "/dashboard/partialrepayment-success",
              element: <Repayloansuccesspartial />,
            },
            {
              path: "/dashboard/loan-statement",
              element: <Loanstatement />,
            },
            {
              path: "/dashboard/loan-statement-success",
              element: <Loanstatementsuccess />,
            },
            {
              path: "/dashboard/change-password",
              element: <Changepassword />,
            },
            {
              path: "/dashboard/support",
              element: <Support />,
            },
            {
              path: "/dashboard/accounts",
              element: <Accounts />,
            },
            {
              path: "/dashboard/accounts/account-status",
              element: <Accountstatus />,
            },
            {
              path: "/dashboard/accounts/verification/:accountType",
              element: <Accountverification />,
            },
            {
              path: "/dashboard/add-money",
              element: <Addmoney />,
            },
            {
              path: "/dashboard/virtual-accounts",
              element: <Virtualaccounts />,
            },
            {
              path: "/dashboard/wallets",
              element: <Wallets />,
            },
            {
              path: "/dashboard/transfer",
              element: <Transfers />,
            },
            {
              path: "/dashboard/beneficiary",
              element: <Createbeneficiary />,
            },
            {
              path: "/dashboard/business-tools",
              element: <BusinessTools />,
            },
						{
              path: "/dashboard/business-tools/orders/:product_id",
              element: <Orders />,
            },
            {
              path: "/dashboard/savings",
              element: <Savings />,
            },
            {
              path: "/dashboard/request-savings",
              element: <Createsavings />,
            },
            {
              path: "/dashboard/request-savings-success",
              element: <Requestsavingssuccess />,
            },
            {
              path: "/dashboard/loans",
              element: <Loans />,
            },
            {
              path: "/dashboard/loan/:reference_code",
              element: <Viewloandetails />,
            },
            // {
            //   path: "/dashboard/payment",
            //   element: <Buy />,
            // },
            {
              path: "/dashboard/create-payment",
              element: <Payment />,
            },
            {
              path: "/dashboard/payment-success",
              element: <Paymentsuccess />,
            },
            {
              path: "/dashboard/payment",
              element: <Paymenthistory />,
            },
            {
              path: "/dashboard/bill-payment/:billType",
              element: <Billpayment />,
            },
            {
              path: "/dashboard/bill-payment-success",
              element: <Billpaymentrequestsuccess />,
            },
            {
              path: "/dashboard/manage-account",
              element: <Manageaccount />,
            },
            {
              path: "/dashboard/update-email",
              element: <Updateemail />,
            },
						{
              path: "/dashboard/update-payment-pin",
              element: <EnablePin />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <Signin />,
      // element: <Slash />,
    },
  ]);

  const config: ThemeConfig = {
    token: {
      colorPrimary: "#019ee1",
    },
  };

  return (
    <ConfigProvider theme={config}>
      <div className="App">
        <RouterProvider
          router={onlineStatus !== "online" ? offlineRoute : router}
        />
      </div>
    </ConfigProvider>
  );
}

export default App;
