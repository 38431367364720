import React, { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { walletcardInterface } from "./walletcard-interface";
import Addmoney from "../addmoney/addmoney";
import { useNavigate } from "react-router-dom";
import { commaNumber, transformCurrencyToSymbol } from "../../utils/helper";

const Walletcard = ({
  isloanCard,
  availableBalance,
  currency,
  name,
  loans,
  updateViewWalletDetails,
  amountCollected,
  repaidAmount,
  totalSaved,
  totalSavedWithInterest,
  isSavingsCard,
  totalInvested,
  totalInvestedWithInterest,
  isInvestmentCard,
}: walletcardInterface) => {
  const navigate = useNavigate();
  const [viewAmount, setViewAmount] = useState(true);
  const [visibleAddMoney, setVisibleAddMoney] = useState(false);

  const closeAddMoney = () => {
    setVisibleAddMoney(false);
  };

  const toggleViewAmount = () => {
    setViewAmount(!viewAmount);
  };

  return (
    <div
      className={`${
        amountCollected !== undefined && repaidAmount !== undefined
          ? "h-40"
          : "h-48"
      } rounded-lg w-[99%] p-6 font-poppins ${
        isloanCard ? "bg-appcolorblue" : "bg-appcolorblue "
      }`}
    >
      <>
        <div className="flex items-baseline justify-between mb-2 lg:flex-row">
          <p
            className={`text-base ${
              isloanCard ? "text-appcolorwhite" : "text-appcolorwhite"
            } mb-2`}
          >
            {isloanCard
              ? "Recent Loan Details"
              : isInvestmentCard
              ? "Recent Investment Details"
              : isSavingsCard
              ? "Recent Savings Details"
              : "Wallet Balance"}
          </p>

          {isInvestmentCard || isSavingsCard ? null : (
            <>
              {!isloanCard ? (
                <>
                  <div>
                    {/* <span
                className="px-2 py-1 mr-2 text-xs font-black cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
                onClick={() => {
                  if (updateViewWalletDetails !== undefined) {
                    updateViewWalletDetails(true);
                  }
                }}
              >
                View Virtual Account Details
              </span> */}
                    <span
                      className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
                      onClick={() => {
                        // setVisibleAddMoney(true);
                        navigate("/dashboard/add-money");
                      }}
                    >
                      ADD MONEY
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {loans !== undefined && loans?.length > 0 && (
                    <span className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcolorred text-appcolorwhite">
                      OVERDUE
                    </span>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>

      {totalInvested !== undefined &&
      totalInvestedWithInterest !== undefined ? (
        <>
          <div className="">
            <p className="text-appcolorwhite">
              Total Invested: {currency}
              {commaNumber(totalInvested)}
            </p>

            <p className="text-appcolorwhite">
              Invested With Interest: {currency}
              {commaNumber(totalInvestedWithInterest)}
            </p>
          </div>
        </>
      ) : (
        <>
          {totalSaved !== undefined && totalSavedWithInterest !== undefined ? (
            <>
              <div className="">
                <p className="text-appcolorwhite">
                  Total Saved: {currency}
                  {commaNumber(totalSaved)}
                </p>

                <p className="text-appcolorwhite">
                  Saved With Interest: {currency}
                  {commaNumber(totalSavedWithInterest)}
                </p>
              </div>
            </>
          ) : (
            <>
              {amountCollected !== undefined && repaidAmount !== undefined ? (
                <>
                  <div className="">
                    <p className="text-appcolorwhite">
                      Amount Collected: {currency}
                      {commaNumber(amountCollected)}
                    </p>

                    <p className="text-appcolorwhite">
                      Amount To Be Paid: {currency}
                      {commaNumber(repaidAmount)}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`flex ${
                      viewAmount ? "items-baseline" : "items-center"
                    }`}
                  >
                    <span className="mb-0 mr-2 text-2xl text-appcolorwhite">
                      {viewAmount
                        ? `${currency?.toLocaleUpperCase()} ${commaNumber(
                            availableBalance
                          )}`
                        : "*********"}
                    </span>
                    {viewAmount ? (
                      <EyeOutlined
                        className="cursor-pointer text-appcolorwhite"
                        onClick={() => {
                          toggleViewAmount();
                        }}
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        className="cursor-pointer text-appcolorwhite"
                        onClick={() => {
                          toggleViewAmount();
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      {isSavingsCard || isInvestmentCard || isloanCard ? null : (
        <div className="pt-10">
          <span
            className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcolorwhite text-appcolorblue"
            onClick={() => {
              // setVisibleAddMoney(true);
              navigate("/dashboard/transfer");
            }}
          >
            Transfer
          </span>
        </div>
      )}

      {isloanCard ? (
        <>
          {availableBalance !== 0 ? (
            <>
              {loans?.length !== 0 ? (
                <>
                  <p className="mb-4 text-sm font-black text-appcolorred">
                    NEXT PAYMENT IS OVERDUE
                  </p>
                  <div className="flex">
                    {/* <div className='mr-2'>
                      <Button
                        htmlType="submit"
                        className='mb-2'
                      >
                        VIEW DETAILS
                      </Button>
                    </div> */}
                    <div>
                      <Button
                        htmlType="submit"
                        className="mb-2"
                        onClick={() => {
                          navigate("/dashboard/repay");
                        }}
                      >
                        REPAY NOW
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <p className="mb-4 text-sm font-black text-appcolorwhite">
                  NO CURRENT LOAN
                </p>
              )}
            </>
          ) : null}
        </>
      ) : null}

      {visibleAddMoney && (
        <Addmoney visible={visibleAddMoney} closeModal={closeAddMoney} />
      )}
    </div>
  );
};

Walletcard.defaultProps = {
  isloanCard: false,
  currency: "₦",
  name: "",
  availableBalance: 0,
  loans: [],
};

export default Walletcard;
