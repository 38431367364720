import React, { useCallback, useEffect, useMemo, useState } from "react";
import Transactionsfilter from "../transactions/transactionsfilter";
import {
  getPaymentDetails,
  sendInitiatePayment,
} from "../../../api/payment/payment";
import {
  Alert,
  Button,
  Form,
  message,
  Modal,
  Pagination,
  PaginationProps,
  Tag,
} from "antd";
import {
  commaNumber,
  openNotificationWithIcon,
  validateNumberAbove,
} from "../../../utils/helper";
import moment from "moment";
import {
  ArrowPathIcon,
  ClipboardDocumentIcon,
  CreditCardIcon,
  DocumentIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import Inputamount from "../../../components/inputamount/inputamount";
import { useDispatch } from "react-redux";
import { updateReloadWallet } from "../../../store/userSlice/userSlice";
import TransactionPin from "../../../components/transactionpin/transactionpin";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";

const Paymenthistory = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
	const hasPinEnabled = useEnabledTransactionPin();

  const [error, setError] = useState(null);
  const [paying, setPaying] = useState(false);
  const [currentPayment, setCurrentPayment] = useState<any>({});
  const [showRepay, setShowRepay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loanDetails, setPaymentDetails] = useState<any>([]);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loanHistory, setPaymentHistory] = useState<any>([]);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [otherFilters, setOtherFilters] = useState<any>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [errors, setErrors] = useState({
    amount: "",
  });

	const [paymentSummaryDetails, setPaymentSummaryDetails] = useState<
	{
		title: string;
		value: any;
	}[]
>([]);
const [detailsToBeSubmitted, setDetailsToBeSubmitted] = useState<any | null>(
	null
);
const [showTransactionPin, setShowTransactionPin] = useState(false);

const closeTransactionPin = () => {
	if (!loading) {
		setShowTransactionPin(false);
		setPaymentSummaryDetails([]);
		setDetailsToBeSubmitted(null);
	}
};

  const closeRepayModal = () => {
    if (!paying) {
      setShowRepay(false);
    }
  };

  const closeShowFilter = () => [setShowFilter(false)];

  const requestData = useMemo(() => {
    let realOtherFilters = {};
    if (otherFilters !== null) {
      realOtherFilters = otherFilters;
    }
    return {
      ...realOtherFilters,
      currency: "ngn",
      // type: "DIRECT",
      limit: otherFilters === null ? limit : 8,
      page: otherFilters === null ? currentPage : 1,
    };
  }, [limit, currentPage, otherFilters]);

  const fetchPaymentHistory = useCallback(async () => {
    setLoadingHistory(true);

    try {
      const res = await getPaymentDetails({
        ...requestData,
      });
      const { status, data } = res.data;

      // setPaymentDetails([
      //   {
      //     repayment_amount: 0,
      //     total_collected: 0,
      //   },
      // ]);

      if (status === "success") {
        if (data !== null) {
          setPaymentHistory(data ?? []);
        }
      } else {
        message.warning(`Get Payment History, ${res.data.message}`);
      }

      setLoadingHistory(false);
    } catch (error) {
      setLoadingHistory(false);
      message.error("Something went wrong: Get Payment History");
    }
  }, [requestData]);

  useEffect(() => {
    fetchPaymentHistory();
  }, [fetchPaymentHistory]);

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
  };

  const getTransactionIcon = (name: string) => {
    switch (name) {
      case "Credit":
        return <CreditCardIcon />;
      case "Debit":
        return <CreditCardIcon />;
      default:
        return <ClipboardDocumentIcon />;
    }
  };

  const updateField = (name: string, value?: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

	const constructPaymentSummaryDetails = (values: {
    reference_code: any;
    amount: any;
    partial: string;
  }) => {
    const detail: any = [
      {
        title: "Amount",
        value: `₦${commaNumber(values.amount)}`,
      },
    ];

    setPaymentSummaryDetails(detail);
  };

  const followThroughAfterPin = async (otp: string) => {
    try {
      setError(null);
      setPaying(true);

      const res = await sendInitiatePayment({
        ...detailsToBeSubmitted,
        authorization_pin: otp,
      });

      const { status, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Submit Payment", res.data.message);
        setShowRepay(false);
        fetchPaymentHistory();
        dispatch(
          updateReloadWallet({
            reloadWallet: true,
          })
        );

        setPaymentSummaryDetails([]);
        setShowTransactionPin(false);
        setDetailsToBeSubmitted(null);
      } else {
        openNotificationWithIcon("warning", "Payment", res.data.message);
        setError(res?.data?.message);
      }

      setPaying(false);
    } catch (error) {
      setPaying(false);
      // setLoadingServices(false);
      message.error("Something went wrong: Submit Payment");
    }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setPaying(true);
    const { amount } = values;

    const newErrors = { ...errors };

    newErrors["amount"] = validateNumberAbove(amount, 0, "amount");

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        let dataToSend = {
          reference_code: currentPayment?.reference_code,
          amount,
          partial: "Yes",
        };

        setDetailsToBeSubmitted(dataToSend);

        if (hasPinEnabled) {
          constructPaymentSummaryDetails(dataToSend);
          setLoading(false);
          setShowTransactionPin(true);
          return false;
        }

        const res = await sendInitiatePayment({
          ...dataToSend,
        });

        const { status, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            "Submit Payment",
            res.data.message
          );
          setShowRepay(false);
          fetchPaymentHistory();
          dispatch(
            updateReloadWallet({
              reloadWallet: true,
            })
          );
        } else {
          openNotificationWithIcon("warning", "Payment", res.data.message);
          setError(res?.data?.message);
        }

        setPaying(false);
      } catch (error) {
        setPaying(false);
        // setLoadingServices(false);
        message.error("Something went wrong: Submit Payment");
      }
    } else {
      setPaying(false);
    }
  };

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Buy
          </h1>

          <span
            className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl text-appcolorblue"
            onClick={() => {
              navigate("/dashboard/create-payment");
            }}
          >
            Make Payment
          </span>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-lg font-medium b-0 font-poppins text-appcolorgrey">
            Payment History
          </p>

          <div className="flex items-center">
            <span
              className="text-sm font-bold text-appcolorblue"
              onClick={() => {
                if (!loadingHistory) {
                  setShowFilter(true);
                }
              }}
            >
              Filter
            </span>
            {otherFilters !== null && (
              <span
                className="pl-2 text-sm font-bold text-appcolorred"
                onClick={() => {
                  if (!loadingHistory) {
                    setLimit(8);
                    setCurrentPage(1);
                    setOtherFilters(null);
                  }
                }}
              >
                Clear Filter
              </span>
            )}
          </div>
        </div>

        {loadingHistory ? (
          <>
            {[1, 2, 3].map((item) => (
              <div className="mb-4" key={`trans-preloader-${item}`}>
                <Skeletonloader height={"4rem"} />
              </div>
            ))}
          </>
        ) : (
          <>
            {loanHistory.length === 0 ? (
              <div className="flex flex-col items-center justify-center mt-2">
                <div className="w-11 h-11">
                  <DocumentIcon className="cursor-pointer" />
                </div>
                <p className="text-center cursor-pointer font-poppins">
                  No payment history found.
                </p>
                {/* <p className="text-center cursor-pointer font-poppins">
                    Let’s get started
                  </p> */}
              </div>
            ) : (
              <div className="pb-28">
                {loanHistory.map((item: any) => (
                  <div key={item.reference_code} className="relative">
                    <div
                      className="relative flex items-start justify-between mb-5"
                      // onClick={() => {
                      //   navigate(`/dashboard/loan/${item?.reference_code}`, {
                      //     state: {
                      //       item,
                      //     },
                      //   });
                      // }}
                    >
                      <div className="flex items-center">
                        {/* <div className="w-10 h-10 p-2 mr-2 rounded-lg bg-appcolorlightgray">
                        {getTransactionIcon(item.type)}
                      </div> */}
                        <div className="pt-4">
                          <p
                            className={`mb-0 text-sm font-semibold font-ibmplexsans`}
                          >
                            {item?.product?.name}
                          </p>

                          <p
                            className={`mb-0 text-sm font-semibold font-ibmplexsans`}
                          >
                            Collection: {item?.product?.collection}
                          </p>

                          <p className="mb-0 text-sm font-ibmplexsans text-appcolorblack">
                            Status:{" "}
                            <span
                              className={`font-semibold ${
                                item?.status === "Failed" ||
                                item?.status === "Overdue"
                                  ? "text-appcolorred"
                                  : item?.status === "Successful" ||
                                    item?.status === "Disbursed" ||
                                    item?.status === "Approved" ||
                                    item?.status === "Repaid"
                                  ? "text-appcolorgreen"
                                  : item?.status === "Processing"
                                  ? "text-appcoloryellow"
                                  : "text-appcolorblack"
                              }`}
                            >
                              {item.status}
                            </span>
                          </p>

                          <p className={`mb-0 text-sm font-ibmplexsans`}>
                            Product Amount: ₦{" "}
                            {commaNumber(
                              parseFloat(item?.total_amount).toFixed(2)
                            )}
                          </p>

                          {item?.status === "Partial" && (
                            <>
                              <p className={`mb-0 text-sm  font-ibmplexsans`}>
                                Total Amount Paid: ₦{" "}
                                {commaNumber(
                                  parseFloat(
                                    item?.instalment?.total_paid
                                  ).toFixed(2)
                                )}
                              </p>

                              <p className={`mb-0 text-sm font-ibmplexsans`}>
                                Total Amount Left: ₦{" "}
                                {commaNumber(
                                  parseFloat(
                                    item?.instalment?.remaining_amount
                                  ).toFixed(2)
                                )}
                              </p>
                            </>
                          )}

                          {/* <p className="text-xs">{item?.duration} days</p>
                        <span className="text-xs">
                          {moment(item?.added_timestamp).format("lll")}
                        </span> */}
                        </div>
                      </div>

                      <div className="pt-4 text-right">
                        {/* <p
                        className={`mb-0 text-sm font-semibold font-ibmplexsans`}
                      >
                        ₦{" "}
                        {commaNumber(
                          parseFloat(item?.product_amount).toFixed(2)
                        )}
                      </p> */}

                        {/* <span className="text-xs">{item.type}</span> */}
                      </div>
                    </div>
                    {item?.status === "Partial" && (
                      <div className="absolute bottom-[0.05rem] right-[-0.5rem]cursor-pointer">
                        <Tag
                          color="blue"
                          onClick={() => {
                            setShowRepay(true);
                            setCurrentPayment(item);
                          }}
                        >
                          Repay
                        </Tag>
                      </div>
                    )}
                  </div>
                ))}

                {loanHistory.length > 0 && (
                  <div className="mt-8 mb-4">
                    <Pagination
                      defaultCurrent={currentPage}
                      defaultPageSize={limit}
                      total={total}
                      onChange={onChange}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {showFilter && (
          <Transactionsfilter
            visible={showFilter}
            closeModal={closeShowFilter}
            setOtherFilters={setOtherFilters}
            isCards
          />
        )}

        {showRepay && (
          <Modal
            onCancel={closeRepayModal}
            onOk={closeRepayModal}
            open={showRepay}
            footer={null}
            destroyOnClose
          >
            <div>
              <div>
                <h1 className="mb-2 text-xl font-semibold font-ibmplexsans text-appcolorblue">
                  Repay
                </h1>
              </div>

              <div>
                <div className="relative">
                  <div
                    className="relative flex items-start justify-between mb-5"
                    // onClick={() => {
                    //   navigate(`/dashboard/loan/${item?.reference_code}`, {
                    //     state: {
                    //       item,
                    //     },
                    //   });
                    // }}
                  >
                    <div className="flex items-center">
                      {/* <div className="w-10 h-10 p-2 mr-2 rounded-lg bg-appcolorlightgray">
                        {getTransactionIcon(item.type)}
                      </div> */}
                      <div className="pt-4">
                        <p
                          className={`mb-0 text-sm font-semibold font-ibmplexsans`}
                        >
                          {currentPayment?.product?.name}
                        </p>

                        <p
                          className={`mb-0 text-sm font-semibold font-ibmplexsans`}
                        >
                          Collection: {currentPayment?.product?.collection}
                        </p>

                        <p className="mb-0 text-sm font-ibmplexsans text-appcolorblack">
                          Status:{" "}
                          <span
                            className={`font-semibold ${
                              currentPayment?.status === "Failed" ||
                              currentPayment?.status === "Overdue"
                                ? "text-appcolorred"
                                : currentPayment?.status === "Successful" ||
                                  currentPayment?.status === "Disbursed" ||
                                  currentPayment?.status === "Approved" ||
                                  currentPayment?.status === "Repaid"
                                ? "text-appcolorgreen"
                                : currentPayment?.status === "Processing"
                                ? "text-appcoloryellow"
                                : "text-appcolorblack"
                            }`}
                          >
                            {currentPayment.status}
                          </span>
                        </p>

                        <p className={`mb-0 text-sm font-ibmplexsans`}>
                          Product Amount: ₦{" "}
                          {commaNumber(
                            parseFloat(currentPayment?.total_amount).toFixed(2)
                          )}
                        </p>

                        {currentPayment?.status === "Partial" && (
                          <>
                            <p className={`mb-0 text-sm  font-ibmplexsans`}>
                              Total Amount Paid: ₦{" "}
                              {commaNumber(
                                parseFloat(
                                  currentPayment?.instalment?.total_paid
                                ).toFixed(2)
                              )}
                            </p>

                            <p className={`mb-0 text-sm font-ibmplexsans`}>
                              Total Amount Left: ₦{" "}
                              {commaNumber(
                                parseFloat(
                                  currentPayment?.instalment?.remaining_amount
                                ).toFixed(2)
                              )}
                            </p>
                          </>
                        )}

                        {/* <p className="text-xs">{item?.duration} days</p>
                        <span className="text-xs">
                          {moment(item?.added_timestamp).format("lll")}
                        </span> */}
                      </div>
                    </div>

                    <div className="pt-4 text-right">
                      {/* <p
                        className={`mb-0 text-sm font-semibold font-ibmplexsans`}
                      >
                        ₦{" "}
                        {commaNumber(
                          parseFloat(item?.product_amount).toFixed(2)
                        )}
                      </p> */}

                      {/* <span className="text-xs">{item.type}</span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                {error != null && (
                  <div className="mb-3">
                    <Alert message={error} type="error" className="w-full" />
                  </div>
                )}

                <Form layout="vertical" onFinish={onFinish} form={form}>
                  <Form.Item
                    validateStatus={
                      errors?.amount.length > 0 ? "error" : undefined
                    }
                    help={errors?.amount.length > 0 ? errors.amount : undefined}
                    label="Amount"
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Amount is required",
                      },
                    ]}
                  >
                    <Inputamount
                      name="amount"
                      updateField={updateField}
                      placeHolder="Enter amount"
                      currency={"₦"}
                    />
                  </Form.Item>

                  <Form.Item>
                    <div className="flex flex-col items-center justify-center mx-auto mt-0">
                      <Button
                        htmlType="submit"
                        className="mb-2"
                        disabled={loading || paying}
                        loading={paying}
                      >
                        Make Payment
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
        )}
      </div>

			{showTransactionPin && (
        <TransactionPin
          paymentType="payment"
          visible={showTransactionPin}
          closeModal={closeTransactionPin}
          loading={loading}
          paymentDetails={paymentSummaryDetails}
          onSubmit={followThroughAfterPin}
        />
      )}
    </div>
  );
};

export default Paymenthistory;
