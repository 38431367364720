import { http, httpNoAuth } from "..";
import {
  usersignInPayload,
  usersignUpPayload,
  retrieveAccountPayload,
  resetPasswordPayload,
  getOTPCodePayload,
  submitOTPPayload,
  getProvidersPayload,
  getProviderServicesPayload,
  getProviderServiceThresholdPayload,
  getProviderServiceChargePayload,
  addServiceToCartPayload,
  submitLoanRequestPayload,
  uploadSignaturePayload,
  changePasswordPayload,
  checkReferencePayload,
  sendReferencePayload,
  getVerificationTrackPayload,
  handleBVNVerificationPayload,
  handleBVNPhoneNumberVerificationPayload,
  handleIDVerificationPayload,
  handleNextofKinVerificationPayload,
  handleAddressVerificationPayload,
  handleSignatureVerificationPayload,
  handleBusinessDocumentVerificationPayload,
	handleCACRegistrationVerificationPayload,
	handleNINVerificationPayload,
	initiateDeactivateAccountPayload,
	finalizeDeactivateAccountPayload,
	updateEmailPayload,
	updatePinOnPaymentPayload,
} from "./base-interface";

export const signup = (data: usersignUpPayload) => {
  return httpNoAuth.post("/user/registration/email-phone-pin", data);
};

export const signin = (data: usersignInPayload) => {
  return httpNoAuth.post("/user/login/phone-pin", data);
};

export const retrieveAccount = (
  data: retrieveAccountPayload,
  routeValue: string
) => {
  return httpNoAuth.post(`/user/retrieveaccount/${routeValue}`, data);
};

export const resetPassword = (data: resetPasswordPayload) => {
  return httpNoAuth.post("/user/reset-pin", data);
};


export const handleCACRegistrationVerification = (
  data: handleCACRegistrationVerificationPayload
) => {
  return http.post("/user/verification/process/cac-registration", data);
};

export const handleBusinessDocumentVerification = (
  data: handleBusinessDocumentVerificationPayload
) => {
  return http.post("/user/verification/process/business-documents", data);
};

export const handleSignatureVerification = (
  data: handleSignatureVerificationPayload
) => {
  return http.post("/user/verification/process/signature", data);
};

export const handleAddressVerification = (
  data: handleAddressVerificationPayload,
  type: string
) => {
  return http.post(`/user/verification/process/${type}`, data);
};

export const handleNextofKinVerification = (
  data: handleNextofKinVerificationPayload,
  type: string
) => {
  return http.post(`/user/verification/process/${type}`, data);
};

export const handleIDVerification = (data: handleIDVerificationPayload) => {
  return http.post("/user/verification/process/user-identity-document", data);
};

export const handleBVNVerification = (data: handleBVNVerificationPayload) => {
  return http.post("/user/verification/process/bvn", data);
};

export const handleNINVerification = (data: handleNINVerificationPayload) => {
  return http.post("/user/verification/process/nin", data);
};

export const handleBVNPhoneNumberVerification = (
  data: handleBVNPhoneNumberVerificationPayload
) => {
  return http.post(`/user/verification/process/bvn-phone-number`, data);
};

export const getOTPCode = (
  data: getOTPCodePayload,
  verificationType: "email" | "phone"
) => {
  return http.post(`/user/verification/process/${verificationType}`, data);
};

export const submitOTP = (
  data: submitOTPPayload,
  verificationType: "email" | "phone"
) => {
  return http.post(`/user/verification/process/${verificationType}`, data);
};

export const uploadSignature = (data: uploadSignaturePayload) => {
  return http.post("/user/upload-signature", data);
};

export const uploadSelfie = (data: uploadSignaturePayload) => {
  return http.post("/identitydocument/verify", data);
};

export const submitLoanRequest = (data: submitLoanRequestPayload) => {
  return http.post("/service/request", data);
};

export const addServiceToCart = (data: addServiceToCartPayload) => {
  return http.post("/service/add", data);
};

export const getProviderServiceCharge = (
  data: getProviderServiceChargePayload
) => {
  return http.post("/service/charges", data);
};

export const getProviderServiceThreshold = (
  data: getProviderServiceThresholdPayload
) => {
  return http.post("/service/threshold", data);
};

export const getProviderServices = (data: getProviderServicesPayload) => {
  return http.post("/provider/product", data);
};

export const getProviders = (data: getProvidersPayload) => {
  return http.post("/provider/list", data);
};

export const changePassword = (data: changePasswordPayload) => {
  return http.post("/user/account/update/pin", data);
};

export const updateEmail = (data: updateEmailPayload) => {
  return http.post("/user/account/update/email", data);
};

export const checkReference = (data: checkReferencePayload) => {
  return http.post("/relation/reference", data);
};

export const sendReference = (data: sendReferencePayload) => {
  return http.post("/relation/validate", data);
};

export const getVerificationTrack = (data: getVerificationTrackPayload) => {
  return http.post("/user/verification/tracks", data);
};

export const initiateDeactivateAccount = (data: initiateDeactivateAccountPayload) => {
  return http.post("/user/account/deactivation/initiate", data);
};

export const finalizeDeactivateAccount = (data: finalizeDeactivateAccountPayload) => {
  return http.post("/user/account/deactivation/finalise", data);
};

export const updatePinOnPayment = (data: updatePinOnPaymentPayload) => {
  return http.post("/user/account/update/authorization", data);
};