import React, { useEffect, useMemo, useState } from "react";
import { accountVerification } from "./accounts-interface";
import {
  Alert,
  Button,
  Form,
  Input,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { openNotificationWithIcon, updateCommonItems } from "../../../utils/helper";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { RcFile } from "antd/es/upload";
import Inputselect from "../../../components/inputselect/inputselect";
import { handleIDVerification } from "../../../api/base/base";
import { updateUser } from "../../../store/userSlice/userSlice";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const Verifyidentitydocument = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [chosenType, setChosenType] = useState("");
  const [error, setError] = useState(null);
  const [url, setUrl] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    verificationOption: "",
  });

  const state = useSelector((state: RootState) => ({
    token: state.user.token,
    user: state.user.user,
  }));

  const { token, user } = state;

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const convertText = (value: string) => {
    let changedValue = "";
    const words = value.split("_");
    words.forEach((item, index) => {
      if (index !== 0) {
        changedValue += `${index !== 1 ? "  " : ""}${item.toLocaleUpperCase()}`;
      }
    });
    return changedValue;
  };

  const availableOptions = useMemo(() => {
    const getVerificationOption = verificationTodo?.filter(
      (item) => item.parameter === verificationType
    )[0].option;
    const refixOptions: any[] = [];
    getVerificationOption.forEach((item: string) => {
      refixOptions.push({
        id: item,
        name: convertText(item),
      });
    });
    return refixOptions;
  }, [verificationTodo]);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0].status === "uploading") {
      setLoading(true);
      setImageUrl("");
      setUrl("");
    }
    if (newFileList[0].status === "done") {
      setLoading(false);
      if (newFileList[0].response.status === "success") {
        message.success(`${convertText(chosenType)} uploaded successfully`);
        setUrl(newFileList[0]?.response?.data);
      } else {
        openNotificationWithIcon(
          "error",
          convertText(chosenType),
          newFileList[0]?.response?.message
        );
      }

      // Get this url from response in real world.
      getBase64(newFileList[0].originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    if (newFileList[0].status === "error") {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        convertText(chosenType),
        `${convertText(chosenType)} could not be uploaded`
      );
    }
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    if (name === "verificationOption") {
      setChosenType(value);
    }
  };

  useEffect(() => {
    updateField("url", url);
  }, [url]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onFinish = async (values: any) => {
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleIDVerification({
          number: values?.number,
          photo_url: values?.url,
          option: values?.verificationOption,
          state: values?.state,
          lga: values?.lga,
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Verify Identity", message);

          dispatch(
            updateUser({
              user: data,
            })
          );

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
        } else {
          setError(message);
        }
				
				setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error("Something went wrong: Verify Identity");
      }
    } else {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setImageUrl("");
    setFileList([]);
    setUrl("");
    form.setFieldValue("number", undefined);
  }, [chosenType]);

  return (
    <div>
      {error != null && (
        <div className="mb-2">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <Form
        className="w-full"
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Form.Item
          validateStatus={
            errors?.verificationOption.length > 0 ? "error" : undefined
          }
          help={
            errors?.verificationOption.length > 0
              ? errors.verificationOption
              : undefined
          }
          label="Verification Type"
          name="verificationOption"
          rules={[
            {
              required: true,
              message: "Verification Type is required",
            },
          ]}
        >
          <Inputselect
            placeHolder="Choose your preferred verification type"
            updateField={updateField}
            name={"verificationOption"}
            selectOptions={availableOptions}
            selectOptionLabel="name"
            selectValue={form.getFieldValue("verificationOption")}
          />
        </Form.Item>

        {form.getFieldValue("verificationOption") ? (
          <>
            <Form.Item
              label={`${convertText(
                form.getFieldValue("verificationOption")
              )} Number`}
              name="number"
              rules={[
                {
                  required: true,
                  message: `${convertText(
                    form.getFieldValue("verificationOption")
                  )} Number is required`,
                },
              ]}
            >
              <Input placeholder={`Input number`} />
            </Form.Item>

            {form.getFieldValue("verificationOption") === "ng_voters_card" ? (
              <>
                <Form.Item
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "State is required",
                    },
                  ]}
                >
                  <Input placeholder="State" />
                </Form.Item>

                <Form.Item
                  label="Local Government Area"
                  name="lga"
                  rules={[
                    {
                      required: true,
                      message: "Local Government Area is required",
                    },
                  ]}
                >
                  <Input placeholder="Local Government Area" />
                </Form.Item>
              </>
            ) : null}

            <Form.Item
              name="url"
              rules={[
                // {
                //   required: true,
                //   message: `${convertText(
                //     form.getFieldValue("verificationOption")
                //   )} is required`,
                // },
              ]}
              label={
                <span>
                  Upload {convertText(form.getFieldValue("verificationOption"))}
                </span>
              }
            >
              <Upload
                disabled={submitting}
                maxCount={1}
                accept={".png, .jpg, .jpeg"}
                beforeUpload={beforeUpload}
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                onChange={handleChange}
                action={`${REACT_APP_BASE_URL}/misc/file-upload`}
                headers={{
                  Authorization: `Bearer ${token}`,
                }}
                showUploadList={false}
              >
                {imageUrl ? (
                  <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </>
        ) : null}

        <Form.Item>
          <Button
            htmlType="submit"
            className="mb-2 "
            disabled={submitting || loading}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Verifyidentitydocument;
