import React, { useState, useEffect } from "react";
import {
  addRelation,
  getRelationships,
} from "../../../api/relationship/relationship";
import { Alert, Button, Form, message, Tabs } from "antd";
import { checkrelationshipInterface } from "./nextofkin-interface";
import {
  openNotificationWithIcon,
  validatePhoneNumber,
} from "../../../utils/helper";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import Inputselect from "../../../components/inputselect/inputselect";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";

const relationships = [
  "Father",
  "Mother",
  "Sister",
  "Brother",
  "Aunt",
  "Uncle",
  "Son",
  "Daughter",
  "Wife",
  "Husband",
];

const CheckRelationship = ({
  type,
  saving,
  updateSaving,
  label,
  details,
  reloadDetails,
}: checkrelationshipInterface) => {
  const [form] = Form.useForm();
  const [errors, setErrors] = useState({
    phone: "",
    relationship: "",
  });
  const [currentRelation, setCurrentRelation] = useState<any>(null);

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = async (values: any) => {
    const { phone, relationship } = values;

    updateSaving(true);
    const newErrors = { ...errors };

    if (!validatePhoneNumber(`+234${phone}`)) {
      newErrors["phone"] = "Invalid phone number";
    } else {
      newErrors["phone"] = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await addRelation({
          phone: `0${phone}`,
          type,
          relationship:
            type === "guarantor" ? "others" : relationship.toLowerCase(),
        });

        const { status } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", `Add ${label}`, res.data.message);

          reloadDetails();
        } else {
          openNotificationWithIcon("warning", `Add ${label}`, res.data.message);
        }

        updateSaving(false);
      } catch (error) {
        updateSaving(false);
        message.error("Something went wrong: Get wallets");
      }
    } else {
      updateSaving(false);
    }
  };

  useEffect(() => {
    if (details !== undefined) {
      if (details.length > 0) {
        const getCurrentRelation = details.filter((item) => item.type === type);
        if (getCurrentRelation.length > 0) {
          setCurrentRelation(getCurrentRelation[0]);
        }
      }
    }
  }, [details, type]);

  const getAppropriateText = (
    type: "guarantor" | "next_of_kin_primary" | "next_of_kin_secondary"
  ) => {
    if (type === "guarantor") {
      return "Guarantor";
    }

    if (type === "next_of_kin_primary") {
      return "Primary next of kin";
    }

    if (type === "next_of_kin_secondary") {
      return "Secondary next of kin";
    }
  };

  return (
    <div>
      {currentRelation === null || currentRelation === undefined ? (
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Form.Item
            validateStatus={errors?.phone.length > 0 ? "error" : undefined}
            help={errors?.phone.length > 0 ? errors.phone : undefined}
            label={`${label} Phone Number`}
            name="phone"
            rules={[
              {
                required: true,
                message: "Phone Number is required",
              },
            ]}
          >
            <Inputwithaddon
              updateField={updateField}
              name="phone"
              placeHolder="8000000000"
              addonBefore="+234"
            />
          </Form.Item>

          {type !== "guarantor" && (
            <Form.Item
              validateStatus={
                errors?.relationship.length > 0 ? "error" : undefined
              }
              help={
                errors?.relationship.length > 0
                  ? errors.relationship
                  : undefined
              }
              label="Relationship"
              name="relationship"
              rules={[
                {
                  required: true,
                  message: "Loan Payment is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Choose Relationship"
                updateField={updateField}
                name={"relationship"}
                selectOptions={relationships}
                optionHasValue={true}
              />
            </Form.Item>
          )}

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto">
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={saving}
                loading={saving}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      ) : (
        <div>
          <Alert
            message={
              <div>
                <p>
                  Your {getAppropriateText(type)} is{" "}
                  {`${
                    currentRelation?.status === "Pending"
                      ? " pending approval."
                      : " added successfully."
                  }`}
                </p>
                <p>
                  Name:{" "}
                  <span className="font-bold">
                    {currentRelation?.firstname} &nbsp;{" "}
                    {currentRelation?.lastname}
                  </span>
                </p>
                <p>
                  Phone:{" "}
                  <span className="font-bold">{currentRelation?.phone}</span>
                </p>
              </div>
            }
            type={currentRelation?.status === "Pending" ? "info" : "success"}
          />
        </div>
      )}
    </div>
  );
};

const Nextofkin = () => {
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const [saving, setSaving] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    try {
      const res = await getRelationships({});

      const { status, data } = res.data;

      if (status === "success") {
        if (data !== undefined) {
          setDetails(data);
        }
      } else {
        message.warning(`Get relationships, ${res.data.message}`);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Get relationships");
    }
  };

  useEffect(() => {
    getDetails();

    // eslint-disable-next-line
  }, []);

  const reloadDetails = () => {
    getDetails();
  };

  const updateSaving = (value: boolean) => {
    setSaving(value);
  };

  return (
    <div className="mt-4">
      <div className="w-11/12 mx-auto  lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Next of Kin / Guarantors
          </h1>
        </div>

        <div className="flex justify-center mb-0">
          <img src="/img/vectors/nextofkin.svg" alt="paymentsuccess" />
        </div>

        <p className="mb-8 text-sm font-normal text-center font-poppins text-appcolorgrey">
          {details?.length > 0 ? (
            <>Your Guarantors on File</>
          ) : (
            <>
              Sorry, you don't have any Next of Kin
              <br />
              or Guarantor on file.
            </>
          )}
        </p>

        <div>
          {loading ? (
            <div>
              <Skeletonloader height={"10rem"} />
            </div>
          ) : (
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  disabled: saving,
                  label: "Guarantor",
                  key: "1",
                  children: (
                    <CheckRelationship
                      label="Guarantor"
                      type="guarantor"
                      saving={saving}
                      updateSaving={updateSaving}
                      details={details}
                      reloadDetails={reloadDetails}
                    />
                  ),
                },
                {
                  disabled: saving,
                  label: "Next of Kin 1",
                  key: "2",
                  children: (
                    <CheckRelationship
                      label="Next of kin"
                      type="next_of_kin_primary"
                      saving={saving}
                      updateSaving={updateSaving}
                      details={details}
                      reloadDetails={reloadDetails}
                    />
                  ),
                },
                {
                  disabled: saving,
                  label: "Next of Kin 2",
                  key: "3",
                  children: (
                    <CheckRelationship
                      label="Next of kin"
                      type="next_of_kin_secondary"
                      saving={saving}
                      updateSaving={updateSaving}
                      details={details}
                      reloadDetails={reloadDetails}
                    />
                  ),
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Nextofkin;
