import React, { useState } from 'react'
import { uploadsignaturemodalInterface } from './uploadsignaturemodal-interface'
import { Modal, Button, message, Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { REACT_APP_BASE_URL } from '../../utils/appdetails';
import { openNotificationWithIcon } from '../../utils/helper';
import { uploadSignature } from '../../api/base/base';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const Uploadsignaturemodal = ({ visible, closeModal }: uploadsignaturemodalInterface) => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>('');
  const [saving, setSaving] = useState(false)
  const [url, setUrl] = useState('')

  const state = useSelector((state: RootState) => ({
    token: state.user.token,
  }))

  const { token } = state


  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Image must smaller than 5MB!');
    }
    return isJpgOrPng && isLt2M;
  };


  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    let fileList = [...info.fileList];

    fileList = fileList.slice(-1);

    if (info.file.status === 'uploading') {
      setLoading(true);
      setImageUrl('');
      setUrl('')
      return;
    }
    if (info.file.status === 'done') {
      if (fileList[0]?.response?.status === 'success') {
        message.success("Signature uploaded successfully")
        setUrl(fileList[0]?.response?.data)
      } else {
        openNotificationWithIcon("error", "Upload Signature", fileList[0]?.response?.message)
      }

      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    if (info.file.status === 'error') {
      setLoading(false);
      openNotificationWithIcon("error", "Upload Signature", "Signature could not be uploaded")
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const sendSignature = async () => {
    setSaving(true)
    try {
      const res = await uploadSignature({
        file_url: url
      })

      const { status } = res.data

      if (status === 'success') {
        openNotificationWithIcon("success", "Save  Signature", res.data.message)
        closeModal()
      } else {
        message.warning(`Save signature, ${res.data.message}`)
      }

      setSaving(false)

    } catch (error) {
      setSaving(false)
      message.error('Something went wrong: Save signature')
    }
  }

  return (
    <div>
      <Modal
        onCancel={loading || saving ? undefined : closeModal}
        onOk={loading || saving ? undefined : closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div>
            <h1 className='mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue'>Upload Signature</h1>
          </div>

          <div className='mt-4'>
            <div>
              <Upload
                headers={{
                  "Authorization": `Bearer ${token}`
                }}
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${REACT_APP_BASE_URL}/misc/file-upload`}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                accept={'.png, .jpg, .jpeg'}
              >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            </div>

            <div className='flex justify-between mt-4'>
              <div className=''>
                <Button
                  disabled={loading || saving}
                  onClick={() => {
                    setImageUrl('')
                    setUrl('')
                  }}
                >
                  Clear Signature
                </Button>
              </div>

              <div>
                <Button
                  disabled={loading || saving || imageUrl === '' || url === ''}
                  onClick={() => {
                    sendSignature()
                  }}
                  loading={saving}
                >
                  Save Signature
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Uploadsignaturemodal