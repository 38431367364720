import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Button, Tabs, TabsProps, Tag, message } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//@ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import Deactivateaccountmodal from "./deactivateaccount/deactivateaccountmodal";
import {
  UserOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";


const accountTypes = [
	{
		name: "Starter",
		requirements: ["BVN", "BVN Phone Number"],
		limits: "₦50,000 - ₦500,000",
	},
	{
		name: "Rated",
		requirements: ["NIN",],
		limits: "up to ₦5,000,000",
	},
	{
		name: "Standard",
		requirements: [
			"Residential Address",
		],
		limits: "up to ₦50,000,000",
	},
	{
		name: "Merchant",
		requirements: [
			"Business Documents",
			"Business Address",
			"CAC Registration",
		],
		limits: "up to ₦500,000,000",
	},
];


export const convertKYCToTier = (value: string) => {
	switch (value.toLowerCase()) {
		case accountTypes[0].name.toLowerCase():
			return "Tier 1";

		case accountTypes[1].name.toLowerCase():
			return "Tier 2";

		case accountTypes[2].name.toLowerCase():
			return "Tier 3";

		default:
			return value;
	}
};

const Accountstatus = () => {
  const navigate = useNavigate();

  const [activeTabKey, setActiveTabKey] = useState<undefined | string>(
    undefined
  );
  const [showDeactivateAccountModal, setShowDeactivateAccountModal] =
    useState(false);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const closeDeactivateAccountModal = () => {
    setShowDeactivateAccountModal(false);
  };



  const openUp = (accountType: string) => {
    navigate(`/dashboard/accounts/verification/${accountType.toLowerCase()}`);
  };

  const getAccountTypeDetails = (accountName: string) => {
    const findAccount = accountTypes.filter(
      (item) => item?.name.toLowerCase() === accountName.toLowerCase()
    );
    return findAccount[0];
  };

  const getAccountClass = (accountType: string) => {
    if (
      user?.kyc?.current_account_type.toLowerCase() ===
      accountType.toLowerCase()
    ) {
      return "bg-appcolorblue cursor-pointer";
    }
    return "bg-appcolorlightgray";
  };

  const getVerificationStatus = () => {
    if (user?.kyc?.current_track === 0) {
      return "Get Started";
    }
    if (user?.kyc?.remaining_track === 0) {
      return "Done";
    }
    if (user?.kyc?.expected_track > user?.kyc?.remaining_track) {
      return "In Progress";
    }
    return "";
  };

  const onChange = (key: string) => {
    setActiveTabKey(key);
  };



  // const items: TabsProps["items"] = [
  //   {
  //     key: "1",
  //     label: "Profile",
  //     children: (
  //       <div>
  //         <div className="mb-8 font-poppins">
  //           {(user?.firstname || user?.lastname) && (
  //             <p className="mb-2">
  //               <span className="font-medium text-appcolorblue">
  //                 <UserOutlined /> Name
  //               </span>
  //               : {user?.firstname} {user?.lastname}{" "}
  //             </p>
  //           )}

  //           {user?.dob && (
  //             <p className="mb-2">
  //               <span className="font-medium text-appcolorblue">
  //                 <ClockCircleOutlined /> DOB
  //               </span>
  //               : {moment(user?.dob).format("ll")}
  //             </p>
  //           )}

  //           {user?.created_on && (
  //             <p className="mb-2">
  //               <span className="font-medium text-appcolorblue">
  //                 <CalendarOutlined /> Account Created On
  //               </span>
  //               : {moment(user?.created_on).format("ll")}
  //             </p>
  //           )}

  //           {user?.email && (
  //             <p className="mb-2">
  //               <span className="font-medium text-appcolorblue">
  //                 <MailOutlined /> Email
  //               </span>
  //               : {user?.email ?? "-"}
  //             </p>
  //           )}

  //           {user?.phone && (
  //             <p className="mb-2">
  //               <span className="font-medium text-appcolorblue">
  //                 <PhoneOutlined />  Phone
  //               </span>
  //               : {user?.phone}
  //             </p>
  //           )}

  //           {user?.referral_code && (
  //             <p className="flex items-center mb-2">
  //               <span className="font-medium text-appcolorblue">
  //                 <PlusCircleOutlined /> Referrer Code
  //               </span>
  //               :{" "}
  //               {user?.referral_code ? (
  //                 <div className="pl-2">
  //                   <CopyToClipboard
  //                     text={user?.referral_code}
  //                     onCopy={() => {
  //                       message.info("Referral code copied to clipboard");
  //                     }}
  //                   >
  //                     <div className="flex items-center p-1 px-2 text-sm bg-appcolorgray rounded-[0.5rem]">
  //                       <span className="pr-8">{user?.referral_code}</span>
  //                       <ClipboardDocumentIcon className="w-4 h-4 text-appcolorwhite" />
  //                     </div>
  //                   </CopyToClipboard>
  //                 </div>
  //               ) : (
  //                 "-"
  //               )}
  //             </p>
  //           )}

  //           <Button
  //             htmlType="submit"
  //             className="mt-4 mb-4 red-btn"
  //             onClick={() => {
  //               setShowDeactivateAccountModal(true);
  //             }}
  //           >
  //             Deactivate Account
  //           </Button>
  //         </div>
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: "Account Status",
  //     children: (

  //     ),
  //   },
  // ];

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Account Status
          </h1>
        </div>

        <div>
          <div className="pb-[6rem]">
            {user?.kyc?.available_account_type?.map(
              (item: any, index: number) => (
                <div
                  key={`acc-type-${item}-${index}`}
                  className={`p-4 mb-3 rounded-lg shadow-lg 
								${getAccountClass(item)}
								`}
                  onClick={() => {
                    openUp(item);
                    // if (
                    //   user?.kyc?.current_account_type.toLowerCase() ===
                    //   item.toLowerCase()
                    // ) {
                    //   openUp(item);
                    // }
                  }}
                >
                  {user?.kyc?.current_account_type.toLowerCase() ===
                    item.toLowerCase() && (
                    <span className="text-xs font-bold text-white">
                      Current
                    </span>
                  )}
                  <div className="flex items-center mb-1">
                    <h1
                      className={` text-xl font-bold capitalize ${
                        user?.kyc?.current_account_type.toLowerCase() ===
                        item.toLowerCase()
                          ? "text-white"
                          : "text-black"
                      }`}
                    >
                      {convertKYCToTier(item)}
                    </h1>
                  </div>

                  <h1
                    className={`mb-4 text-md font-bold capitalize ${
                      user?.kyc?.current_account_type.toLowerCase() ===
                      item.toLowerCase()
                        ? "text-white"
                        : "text-black"
                    }`}
                  >
                    Limits: {getAccountTypeDetails(item).limits}
                  </h1>

                  <div
                    className={`mb-4 ${
                      user?.kyc?.current_account_type.toLowerCase() ===
                      item.toLowerCase()
                        ? "text-white"
                        : "text-black"
                    }  `}
                  >
                    <p className="text-xs">Requirements</p>
                    <ol className="text-sm">
                      {getAccountTypeDetails(item)?.requirements.map(
                        (itemReq, itemReqIndex) => (
                          <li
                            key={`acc-type-${item}-${index}-req-${itemReqIndex}`}
                          >
                            {itemReqIndex + 1}. {itemReq}
                          </li>
                        )
                      )}
                    </ol>
                  </div>

                  {user?.kyc?.current_account_type.toLowerCase() ===
                    item.toLowerCase() && (
                    <div className="text-xs text-white">
                      <span>Verification Status:</span>
                      <span className="pl-1">{getVerificationStatus()}</span>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>

        {/* <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            My Account Status
          </h1>
        </div> */}
      </div>

      {
        <Deactivateaccountmodal
          visible={showDeactivateAccountModal}
          closeModal={closeDeactivateAccountModal}
        />
      }
    </div>
  );
};

export default Accountstatus;
