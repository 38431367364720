import React, { useState } from "react";
import { Form, Input, Button, message, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { changePassword, updatePinOnPayment } from "../../../api/base/base";
import { openNotificationWithIcon } from "../../../utils/helper";
import { RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/userSlice/userSlice";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";

const EnablePin = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasPinEnabled = useEnabledTransactionPin();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    newPin: "",
    confirmPin: "",
  });

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const onFinish = async (values: any) => {
    const { newPin, confirmPin } = values;

    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    if (newPin !== confirmPin) {
      newErrors["confirmPin"] = "PIN and Confirm PIN must be the same";
      newErrors["newPin"] = "PIN and Confirm PIN must be the same";
    } else {
      newErrors["confirmPin"] = "";
      newErrors["newPin"] = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await updatePinOnPayment({
          pin: newPin,
          enable: "Yes",
        });

        const { status, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon(
            "success",
            `${hasPinEnabled ? "Update" : "Enable"} Transaction PIN`,
            res.data.message
          );

          dispatch(
            updateUser({
              user: {
                ...user,
                security: data?.security,
              },
            })
          );

          navigate("/dashboard");
        } else {
          setError(res.data.message);
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        message.error(
          `Something went wrong:  ${
            hasPinEnabled ? "Update" : "Enable"
          } Transaction PIN`
        );
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4 mb-8">
      <div className="flex justify-center ">
        <img src="/img/logo/logo.png" alt="logo" className="w-16" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        {hasPinEnabled ? "Update" : "Enable"} Transaction PIN
      </h1>

      <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey lg:w-3/12">
        Kindly input your desired transaction PIN.
      </p>

      {error != null && (
        <div className="flex justify-center w-11/12 mx-auto mb-4 lg:w-3/12">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="flex justify-center w-11/12 mx-auto lg:w-3/12">
        <Form
          className="w-full"
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            validateStatus={errors?.newPin.length > 0 ? "error" : undefined}
            help={errors?.newPin.length > 0 ? errors.newPin : undefined}
            label="Enter New PIN"
            name="newPin"
            rules={[
              {
                required: true,
                message: "New PIN is required",
              },
            ]}
          >
            <Input.Password placeholder="New PIN" minLength={4} maxLength={4} />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.confirmPin.length > 0 ? "error" : undefined}
            help={errors?.confirmPin.length > 0 ? errors.confirmPin : undefined}
            label="Confrim PIN"
            name="confirmPin"
            rules={[
              {
                required: true,
                message: "Confirm PIN is required",
              },
            ]}
          >
            <Input.Password
              placeholder="Confirm PIN"
              minLength={4}
              maxLength={4}
            />
          </Form.Item>

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto my-8 mb-4">
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EnablePin;
