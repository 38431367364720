import { Alert, Button, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { IObjectKeysStringsOnly } from "../../../utils/helpers-interface";
import {
  getSavingsOffers,
  getSavingsType,
  submitSavingsPaymentRequest,
  submitSavingsRequest,
} from "../../../api/savings/savings";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  commaNumber,
  convertToSlug,
  openNotificationWithIcon,
  validateNumberAbove,
} from "../../../utils/helper";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import UploadRequestInfo from "../requestloan/uploadRequestInfo";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import Inputamount from "../../../components/inputamount/inputamount";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const scheduleTypes = [
  {
    id: "Day",
    name: "Daily",
  },
  {
    id: "Week",
    name: "Weekly",
  },
  {
    id: "Month",
    name: "Monthly",
  },
  {
    id: "Year",
    name: "Yearly",
  },
	{
    id: "Quarter",
    name: "Quarterly",
  },
	{
    id: "Biannual",
    name: "Biannually",
  },
];

const Createsavings = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    token: state.user.token,
  }));

  const { user, token } = state;

  const [duration, setDuration] = useState<string | null>(null);
  const [savedRequest, setSavedRequest] = useState<any>({});
  const [showSummary, setShowSummary] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [offerInformation, setOfferInformation] = useState<any>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [offers, setOffers] = useState([]);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [currentSelectedSavingsType, setCurrentSelectedSavingsType] = useState<{
    min_duration: string;
    max_duration: string;
    min_amount: string;
    max_amount: string;
    id: string;
    recurring: string;
  } | null>(null);
  const [loadingSavingsType, setLoadingSavingsType] = useState(false);
  const [savingsType, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    savingsType: "",
    product: "",
    amount: "",
    duration: "",
    narration: "",
    schedule: "",
  });

  const [uploading, setUploading] = useState(false);
  const [requestInfo, setRequestInfo] = useState<
    {
      name: string;
      type:
        | "select"
        | "text"
        | "number"
        | "alphanumeric"
        | "audio"
        | "video"
        | "pdf"
        | "image";
      option: string[];
      required: string;
    }[]
  >([]);

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);

    const { amount, savingsType, duration, narration, ...rest } = values;
    const request_info: {
      name: string;
      input: string;
    }[] = [];

    const resting: IObjectKeysStringsOnly = rest;

    Object.entries(resting).forEach(([key, value]) => {
      request_info.push({
        name: key?.split("-").join(" "),
        input: value,
      });
    });

    const newErrors = { ...errors };

    newErrors["amount"] = validateNumberAbove(amount, 0, "amount");
    newErrors["duration"] = validateNumberAbove(amount, 0, "duration");

    if (
      currentSelectedSavingsType?.max_amount !==
      currentSelectedSavingsType?.min_amount
    ) {
      if (Number(amount) < Number(currentSelectedSavingsType?.min_amount)) {
        newErrors["amount"] = `Min amount is ₦${commaNumber(
          Number(currentSelectedSavingsType?.min_amount)
        )}`;
      }
      if (Number(amount) > Number(currentSelectedSavingsType?.max_amount)) {
        newErrors["amount"] = `Max amount is ₦${commaNumber(
          Number(currentSelectedSavingsType?.max_amount)
        )}`;
      }
    }

    if (
      currentSelectedSavingsType?.max_duration !==
      currentSelectedSavingsType?.min_duration
    ) {
      if (Number(duration) < Number(currentSelectedSavingsType?.min_duration)) {
        newErrors["duration"] = `Min duration is ${commaNumber(
          Number(currentSelectedSavingsType?.min_duration)
        )} days`;
      }
      if (Number(duration) > Number(currentSelectedSavingsType?.max_duration)) {
        newErrors["duration"] = `Max duration is ${commaNumber(
          Number(currentSelectedSavingsType?.max_duration)
        )} days`;
      }
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        setSavedRequest({
          currency: "ngn",
          request_info,
          id: currentSelectedSavingsType?.id as string,
          amount,
          duration,
          narration: values?.narration,
          schedule: values?.schedule,
        });

        const res = await getSavingsOffers({
          currency: "ngn",
          request_info,
          id: currentSelectedSavingsType?.id as string,
          amount,
          duration,
          schedule: values?.schedule,
        });

        const { status, data } = res.data;

        if (status === "success") {
          // await initiatePayment(data?.reference_code);
          openNotificationWithIcon(
            "success",
            "Submit Savings Request",
            res.data.message
          );
          setOfferInformation(data);
          setShowSummary(true);
        } else {
          // openNotificationWithIcon(
          //   "warning",
          //   "Submit Loan Offer",
          //   res.data.message
          // );
          setError(res?.data?.message);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        // setLoadingServices(false);
        message.error("Something went wrong: Submit Savings Request");
      }
    } else {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    errorInfo.errorFields.forEach((item: any) => {
      setErrors((prevState) => ({
        ...prevState,
        [item.name[0]]: item.errors.length ? item.errors[0] : "",
      }));
    });
  };

  const fetchCategoriesTypes = async () => {
    setLoadingSavingsType(true);
    try {
      const res = await getSavingsType({
        currency: "ngn",
      });
      const { status, data } = res.data;

      if (status === "success") {
        if (data !== null) {
          setCategories(data);
        }
      } else {
        message.warning(`Get Savings Type, ${res.data.message}`);
      }

      setLoadingSavingsType(false);
    } catch (error) {
      setLoadingSavingsType(false);
      message.error("Something went wrong: Get Savings Type");
    }
  };

  useEffect(() => {
    fetchCategoriesTypes();
    // eslint-disable-next-line
  }, []);

  const initiatePayment = async (referenceCode: string) => {
    try {
      setError(null);
      setSubmitting(true);

      const res = await submitSavingsPaymentRequest({
        reference_code: referenceCode,
        amount: savedRequest?.amount,
      });

      const { status, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Savings", res.data.message);
        form.resetFields();
        navigate("/dashboard/request-savings-success");
      } else {
        openNotificationWithIcon("warning", "Savings", res.data.message);
        setError(res?.data?.message);
      }

      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      message.error("Something went wrong: Submit Savings");
    }
  };

  const submitOffer = async () => {
    try {
      setSubmitting(true);
      const res = await submitSavingsRequest({
        ...savedRequest,
      });

      const { status, data } = res.data;

      if (status === "success") {
        await initiatePayment(data?.reference_code);
        // openNotificationWithIcon("success", "Submit Savings", res.data.message);
      } else {
        openNotificationWithIcon("warning", "Submit Savings", res.data.message);
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      // setLoadingServices(false);
      message.error("Something went wrong: Submit savings");
    }
  };

  const updateField = (name: string, value?: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (name === "savingsType") {
      const filteredSavings: any[] = savingsType.filter(
        (item: any) => item?.id === value
      );
      setCurrentSelectedSavingsType(filteredSavings[0]);
      setRequestInfo(
        typeof filteredSavings[0]?.request_info === "string"
          ? []
          : filteredSavings[0]?.request_info ?? []
      );
      form.setFieldValue("schedule", undefined);
      // fetchSavingsOffer(value as string);
    }
  };

  const constructFormInputs = () => {
    return (
      <>
        {requestInfo?.map((item, index) => (
          <Form.Item
            key={index}
            label={item.name}
            name={convertToSlug(item.name)}
            rules={[
              {
                required: item.required === "Yes",
                message: `${
                  item?.type === "select"
                    ? "Select"
                    : item?.type === "alphanumeric" ||
                      item?.type === "number" ||
                      item?.type === "text"
                    ? "Input"
                    : "Upload"
                } is required`,
              },
            ]}
          >
            {item.type === "select" && (
              <Inputselect
                placeHolder={`Select ${item.name}`}
                updateField={updateField}
                name={convertToSlug(item.name)}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={item.option}
                optionHasValue
                loading={false}
                showSearch={false}
              />
            )}
            {item.type === "text" || item.type === "alphanumeric" ? (
              <Input
                name={convertToSlug(item.name)}
                placeholder={` ${item.name}`}
                onChange={(e: any) => {
                  updateField(convertToSlug(item.name), e.target.value);
                }}
              />
            ) : null}

            {item.type === "number" && (
              <Inputwithaddon
                updateField={updateField}
                name={convertToSlug(item.name)}
                placeHolder="8000000000"
                addonBefore="+234"
              />
            )}

            {item.type === "pdf" ||
            item.type === "image" ||
            item.type === "audio" ||
            item.type === "video" ? (
              <UploadRequestInfo
                token={token}
                type={item.type}
                name={convertToSlug(item.name)}
                submitting={loading}
                updateField={updateField}
                uploadName={item.name}
                setUploading={setUploading}
              />
            ) : null}
          </Form.Item>
        ))}
      </>
    );
  };

  useEffect(() => {
    if (currentSelectedSavingsType !== null) {
      if (
        currentSelectedSavingsType?.min_amount ===
        currentSelectedSavingsType?.max_amount
      ) {
        form.setFieldValue("amount", currentSelectedSavingsType?.max_amount);
        setAmount(Number(currentSelectedSavingsType?.max_amount));
      }
    }
  }, [currentSelectedSavingsType, form.getFieldValue("amount")]);

  useEffect(() => {
    if (currentSelectedSavingsType !== null) {
      if (
        currentSelectedSavingsType?.min_duration ===
        currentSelectedSavingsType?.max_duration
      ) {
        form.setFieldValue(
          "duration",
          currentSelectedSavingsType?.max_duration
        );
        setDuration(currentSelectedSavingsType?.max_duration);
      }
    }
  }, [currentSelectedSavingsType, form.getFieldValue("duration")]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
          Create Savings
        </h1>

        <div>
          {error != null && (
            <div className="mb-3">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              validateStatus={
                errors?.savingsType.length > 0 ? "error" : undefined
              }
              help={
                errors?.savingsType.length > 0 ? errors.savingsType : undefined
              }
              label="Savings Type"
              name="savingsType"
              rules={[
                {
                  required: true,
                  message: "Savings Type is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Savings Type"
                updateField={updateField}
                name={"savingsType"}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={savingsType}
                alternativeValue={"id"}
                selectOptionLabel="name"
                loading={loadingSavingsType}
                showSearch={true}
              />
            </Form.Item>

            {currentSelectedSavingsType !== null && (
              <>
                <Form.Item
                  validateStatus={
                    errors?.amount.length > 0 ? "error" : undefined
                  }
                  help={errors?.amount.length > 0 ? errors.amount : undefined}
                  label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Amount is required",
                    },
                  ]}
                >
                  <Inputamount
                    name="amount"
                    updateField={updateField}
                    placeHolder="Enter amount"
                    currency={"₦"}
                    amountValue={
                      currentSelectedSavingsType?.min_amount ===
                      currentSelectedSavingsType?.max_amount
                        ? amount?.toString()
                        : undefined
                    }
                    disabled={
                      currentSelectedSavingsType?.min_amount ===
                      currentSelectedSavingsType?.max_amount
                    }
                  />
                </Form.Item>

                <Form.Item
                  validateStatus={
                    errors?.duration.length > 0 ? "error" : undefined
                  }
                  help={
                    errors?.duration.length > 0 ? errors.duration : undefined
                  }
                  label="Duration (days)"
                  name="duration"
                  rules={[
                    {
                      required: true,
                      message: "Duration is required",
                    },
                  ]}
                >
                  <Input
                    name="duration"
                    disabled={
                      currentSelectedSavingsType?.min_duration ===
                      currentSelectedSavingsType?.max_duration
                    }
                    placeholder="Duration in days"
                    min="1"
                    type="number"
                    value={
                      currentSelectedSavingsType?.min_duration ===
                      currentSelectedSavingsType?.max_duration
                        ? duration?.toString()
                        : undefined
                    }
                    onChange={() => {
                      setErrors((prevState) => ({
                        ...prevState,
                        ["duration"]: "",
                      }));
                    }}
                  />
                </Form.Item>

                {currentSelectedSavingsType.recurring === "Yes" && (
                  <Form.Item
                    validateStatus={
                      errors?.schedule.length > 0 ? "error" : undefined
                    }
                    help={
                      errors?.schedule.length > 0 ? errors.schedule : undefined
                    }
                    label="Schedule"
                    name="schedule"
                    rules={[
                      {
                        required: true,
                        message: "Schedule is required",
                      },
                    ]}
                  >
                    <Inputselect
                      placeHolder="Select Schedule "
                      updateField={updateField}
                      name={"schedule"}
                      // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                      selectOptions={scheduleTypes}
                      alternativeValue={"id"}
                      selectOptionLabel="name"
                    />
                  </Form.Item>
                )}
              </>
            )}

            <Form.Item
              validateStatus={
                errors?.narration.length > 0 ? "error" : undefined
              }
              help={errors?.narration.length > 0 ? errors.narration : undefined}
              label="Description"
              name="narration"
              rules={[
                {
                  required: true,
                  message: "Description is required",
                },
              ]}
            >
              <Input placeholder="Savings Description" />
            </Form.Item>

            {constructFormInputs()}

            <Form.Item>
              <div className="flex flex-col items-center justify-center mx-auto mt-0 mb-40">
                <Button
                  htmlType="submit"
                  className="mb-2"
                  disabled={
                    loading || loadingSavingsType || uploading || submitting
                  }
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </Form.Item>

            {/* <Form.Item
              validateStatus={errors?.product.length > 0 ? "error" : undefined}
              help={errors?.product.length > 0 ? errors.product : undefined}
              label="Offer"
              name="offer"
              rules={[
                {
                  required: true,
                  message: "Product name is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Offer"
                updateField={updateField}
                name={"offer"}
                // icon={<img src="/img/vectors/hospital.svg" alt="hospital" />}
                selectOptions={offers}
                alternativeValue={"id"}
                selectOptionLabel="name"
                loading={loadingOffers}
                showSearch={true}
              />
            </Form.Item> */}
          </Form>
        </div>
      </div>

      {showSummary && offerInformation !== null && (
        <Modal
          open={showSummary}
          footer={null}
          onCancel={
            submitting
              ? undefined
              : () => {
                  setShowSummary(false);
                }
          }
          onOk={
            submitting
              ? undefined
              : () => {
                  setShowSummary(false);
                }
          }
          destroyOnClose
        >
          <div className="">
            <div>
              <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
                Savings Summary
              </h1>
            </div>

            <p className="mb-2 font-poppins">
              Here is a summary of your savings details{" "}
            </p>

            <div className="p-2 bg-appcolorlightgray">
              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">
                  Saved Amount
                </p>
                <p className="w-[60%] flex justify-end font-poppins">
                  ₦{commaNumber(offerInformation?.request?.saved_amount)}
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins"> Duration</p>
                <p className="w-[60%] flex justify-end font-poppins">
                  {offerInformation?.request?.duration} days
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">Rate</p>
                <p className="w-[60%] flex justify-end font-poppins">
                  {offerInformation?.request?.interest_rate}%
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">
                  Interest Amount
                </p>
                <p className="w-[60%] flex justify-end font-poppins">
                  ₦
                  {commaNumber(
                    offerInformation?.request?.interest_amount.toFixed(2)
                  )}
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">
                  Recollection Amount
                </p>
                <p className="w-[60%] flex justify-end font-poppins">
                  ₦{commaNumber(offerInformation?.request?.recollection_amount)}
                </p>
              </div>

              <div className="flex justify-between mb-4 font-poppins">
                <p className="w-[40%] font-semibold font-poppins">
                  Maturity Date
                </p>
                <p className="w-[60%] flex justify-end font-poppins">
                  {moment(offerInformation?.request?.maturity_date).format(
                    "LL"
                  )}
                </p>
              </div>
            </div>

            <div>
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={submitting}
                loading={submitting}
                onClick={() => {
                  submitOffer();
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Createsavings;
