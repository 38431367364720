import React, { useState } from "react";
import { Form, Input, Button, message, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { formValuesInterface } from "./signup-interface";
import {
  openNotificationWithIcon,
  validatePhoneNumber,
} from "../../utils/helper";
import { signup } from "../../api/base/base";
import Inputwithaddon from "../../components/inputwithaddon/inputwithaddon";
import { useDispatch } from "react-redux";
import { updateUser } from "../../store/userSlice/userSlice";
import Privacymodal from "./privacymodal";
import Termsmodal from "./termsmodal";

const Signup = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    pin: "",
    confirmPin: "",
    // nin: "",
    phone: "",
  });
  const [error, setError] = useState(null);

  const closeShowPrivacy = () => {
    setShowPrivacy(false);
  };

  const closeShowTerms = () => {
    setShowTerms(false);
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onFinish = async (values: formValuesInterface) => {
    const { email, phone, pin, bvn, confirmPin, referrer } = values;

    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    if (pin !== confirmPin) {
      newErrors["confirmPin"] = "PIN and Confirm PIN must be the same";
      newErrors["pin"] = "PIN and Confirm PIN must be the same";
    } else {
      newErrors["confirmPin"] = "";
      newErrors["pin"] = "";
    }

    if (!validatePhoneNumber(`+234${phone}`)) {
      newErrors["phone"] = "Invalid phone number";
    } else {
      newErrors["phone"] = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await signup({
          email,
          pin,
          // bvn,
          referrer,
          phone: `+234${phone}`,
          country_code: "NG",
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          dispatch(
            updateUser({
              user: data,
            })
          );

          openNotificationWithIcon("success", "Sign Up", message);

          navigate("/signin");
        } else {
          setError(message);
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        message.error("Something went wrong: Sign up");
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="mt-20 mb-8">
      <div className="flex justify-center">
        <img src="/img/logo/fulllogo.png" alt="logo" className="w-20 mb-10" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue mt-[-2rem]">
        Create an account
      </h1>

      {/* <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey lg:w-3/12">
        This will only take a minute as we set up your account.
      </p> */}

      {error != null && (
        <div className="flex justify-center w-11/12 mx-auto mb-4 lg:w-3/12">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <div className="flex justify-center w-11/12 mx-auto lg:w-3/12">
        <Form
          className="w-full"
          onFinish={onFinish}
          layout="vertical"
          form={form}
        >
          <Form.Item
            validateStatus={errors?.email.length > 0 ? "error" : undefined}
            help={errors?.email.length > 0 ? errors.email : undefined}
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Invalid Email",
              },
            ]}
          >
            <Input type="email" placeholder="Enter Email" />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.phone.length > 0 ? "error" : undefined}
            help={errors?.phone.length > 0 ? errors.phone : undefined}
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: "Phone Number is required",
              },
            ]}
          >
            <Inputwithaddon
              updateField={updateField}
              name="phone"
              placeHolder="8000000000"
              addonBefore="+234"
            />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.pin.length > 0 ? "error" : undefined}
            help={errors?.pin.length > 0 ? errors.pin : undefined}
            label="PIN"
            name="pin"
            rules={[
              {
                required: true,
                message: "PIN is required",
              },
            ]}
          >
            <Input.Password placeholder="Enter your secured 6-Digit PIN number" />
          </Form.Item>

          <Form.Item
            validateStatus={errors?.confirmPin.length > 0 ? "error" : undefined}
            help={errors?.confirmPin.length > 0 ? errors.confirmPin : undefined}
            label="Confirm PIN"
            name="confirmPin"
            rules={[
              {
                required: true,
                message: "Confirm PIN is required",
              },
            ]}
          >
            <Input.Password placeholder="Confirm PIN" />
          </Form.Item>

          <Form.Item
            label="Referral Code"
            name="referrer"
            rules={
              [
                // {
                //   required: true,
                //   message: "BVN is required",
                // },
              ]
            }
          >
            <Input placeholder="Referrer" />
          </Form.Item>

          <p className="text-poppins">
            By continuing, you agree to Nairex{" "}
            <span
              className="cursor-auto text-appcolorblue"
              onClick={() => {
                window.open(
                  "https://nairex.africa/terms-conditions/",
                  "_blank"
                );
              }}
            >
              Terms
            </span>{" "}
            and{" "}
            <span
              className="cursor-pointer text-appcolorblue"
              onClick={() => {
                window.open("https://nairex.africa/privacy-policy/", "_blank");
              }}
            >
              privacy Policy
            </span>
          </p>

          <Form.Item>
            <div className="flex flex-col items-center justify-center mx-auto mt-8 ">
              <Button
                htmlType="submit"
                className="mb-2"
                disabled={loading}
                loading={loading}
              >
                Register
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <div className="relative w-11/12 mx-auto mb-4 lg:w-3/12">
        <hr className="relative border-2 border-appcolorfaint" />
        <p className="absolute bottom-0 left-0 right-0 z-10 px-2 m-auto text-sm font-normal text-center font-poppins text-appcolorgrey -top-2 bg-appcolorwhite">
          Already have an account?
        </p>
      </div>

      <div className="flex flex-col items-center justify-center w-11/12 mx-auto mb-20 lg:w-3/12">
        <Button
          className="purple-skel"
          onClick={() => {
            navigate("/signin");
          }}
        >
          Log In
        </Button>
      </div>

      {showPrivacy && (
        <Privacymodal visible={showPrivacy} closeModal={closeShowPrivacy} />
      )}

      {showTerms && (
        <Termsmodal visible={showTerms} closeModal={closeShowTerms} />
      )}
    </div>
  );
};

export default Signup;
